import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import { volumeFromPlanes } from "@/utils/volume-utils";
import {
  Canvas,
  selectIElementWorldTransform,
} from "@faro-lotv/app-component-toolbox";
import { FaroButton, FaroText, neutral, TextField } from "@faro-lotv/flat-ui";
import { MAX_NAME_LENGTH } from "@faro-lotv/ielement-types";
import { LotvRenderer } from "@faro-lotv/lotv";
import { Box, Checkbox, Drawer, FormControlLabel, Stack } from "@mui/material";
import { PerformanceMonitor } from "@react-three/drei";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Color } from "three";
import { useComponentScreenshot } from "../../hooks/use-component-screenshot";
import { useCreateAreaAndVolume } from "../../hooks/use-create-area-and-volume";
import { useCurrentScene } from "../mode-data-context";
import { useClippingBoxContext } from "./clipping-box-mode-context";
import { OverviewImagePreview } from "./overview-image-preview";

const DEFAULT_WIDTH = 1024;

/**
 * @returns a sidebar next to the canvas, used for the create area workflow
 */
export function ClippingBoxModeDrawer(): JSX.Element | null {
  const [areaName, setAreaName] = useState("");
  const [shouldCreateAnotherArea, setShouldCreateAnotherArea] = useState(false);
  const [canCreateArea, setCanCreateArea] = useState(false);

  const shouldShowDrawer = useAppSelector(
    selectHasFeature(Features.CreateArea),
  );

  // Check if the area name is valid
  useEffect(() => {
    setCanCreateArea(areaName.length > 0 && areaName.length <= MAX_NAME_LENGTH);
  }, [areaName]);

  const [previewObjectAspectRatio, setPreviewObjectAspectRatio] = useState(1);

  // Component rendered in an offscreen canvas to take a screenshot
  // This is what shows up in the screenshot
  // It's using a useMemo to avoid re-rendering too many times the component
  const renderComponent = useMemo(
    () => (
      <OverviewImagePreview
        showBackgroundPlane={false}
        onAspectRatioChanged={setPreviewObjectAspectRatio}
      />
    ),
    [],
  );

  // Assign the static dimension to the biggest side of the object
  // This is done to avoid the screenshot from being too big
  const { screenshotWidth, screenshotHeight } = useMemo(() => {
    if (previewObjectAspectRatio > 1) {
      return {
        screenshotWidth: DEFAULT_WIDTH,
        screenshotHeight: Math.round(DEFAULT_WIDTH / previewObjectAspectRatio),
      };
    }
    return {
      screenshotWidth: Math.round(DEFAULT_WIDTH * previewObjectAspectRatio),
      screenshotHeight: DEFAULT_WIDTH,
    };
  }, [previewObjectAspectRatio]);

  const { main } = useCurrentScene();
  const transform = useAppSelector(selectIElementWorldTransform(main?.id));

  const { clippingPlanes } = useClippingBoxContext();
  const volumeInfo = volumeFromPlanes(clippingPlanes, transform);

  const createAreaAndVolume = useCreateAreaAndVolume(areaName, volumeInfo);

  const onComponentRendered = useCallback(
    (canvas: HTMLCanvasElement) => {
      createAreaAndVolume(canvas);
    },
    [createAreaAndVolume],
  );

  // Callback used to generate a screenshot
  const createImage = useComponentScreenshot(
    renderComponent,
    screenshotWidth,
    screenshotHeight,
    onComponentRendered,
  );

  if (!shouldShowDrawer) return null;

  return (
    <Drawer
      open
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          position: "unset",
          width: 300,
          backgroundColor: neutral[50],
          p: 1.5,
          gap: 3,
        },
      }}
    >
      <FaroText variant="heading16">Areas</FaroText>
      <FaroText variant="placeholder">
        Fill and check the information about your area.
      </FaroText>
      <Stack
        sx={{
          p: 1.5,
          gap: 2,
          borderRadius: 0.5,
          backgroundColor: neutral[0],
        }}
      >
        <TextField
          label="Name"
          placeholder="Area 1"
          // Stop the box controls from being triggered
          onKeyDown={(e) => e.stopPropagation()}
          onTextChanged={setAreaName}
          fullWidth
          // Allow only a certain number of characters
          inputProps={{ maxLength: MAX_NAME_LENGTH }}
          maxCharacterCount={MAX_NAME_LENGTH}
        />
        <Stack>
          <FaroText variant="labelM">Area overview image</FaroText>
          <OverviewImage />
          <FaroText variant="helpText">
            An image of the area will be automatically generated
          </FaroText>
          <FormControlLabel
            label="Create another area"
            control={
              <Checkbox
                checked={shouldCreateAnotherArea}
                onChange={(ev) => setShouldCreateAnotherArea(ev.target.checked)}
              />
            }
            sx={{
              my: 2,
              "& .MuiTypography-root": {
                fontSize: "14px",
                color: neutral[800],
              },
            }}
          />
          <FaroButton disabled={!canCreateArea} onClick={() => createImage()}>
            Create
          </FaroButton>
        </Stack>
      </Stack>
    </Drawer>
  );
}

function OverviewImage(): JSX.Element {
  return (
    <Box
      component="span"
      sx={{
        width: "100%",
        AspectRatio: "1.75",
      }}
    >
      <Canvas
        gl={(canvas) => {
          const renderer = new LotvRenderer({
            canvas,
            premultipliedAlpha: false,
          });
          // enabling the 'localClippingEnabled' property
          // since the app is going to use a global bounding box
          // in most of its scenes.
          renderer.localClippingEnabled = true;
          return renderer;
        }}
        onCreated={(state) => (state.scene.background = new Color(neutral[50]))}
      >
        <PerformanceMonitor>
          <OverviewImagePreview showBackgroundPlane />
        </PerformanceMonitor>
      </Canvas>
    </Box>
  );
}
